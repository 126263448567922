
export function generateFieldNameForQuestion (question) {
  return `question-${question.id}`
}

export function parseQuestionFromFieldName (name) {
  const expression = /^question-(\d+)$/g
  const matches = expression.exec(name)
  if (!matches) {
    return null
  }
  return {
    question: parseInt(matches[1])
  }
}

export function sortByOrder (current, compare) {
  return current.order - compare.order
}

export function reduceSections (acc, cur) {
  cur.questions = []
  acc[cur.id] = cur
  return acc
}

export function reduceQuestions (acc, cur) {
  cur.children = []
  cur.parent = null
  cur.control_values = {} 
  acc[cur.id] = cur
  return acc
}

export function sortedSectionArray (sections) {
  return Object.keys(sections)
    .map(key => {
      return sections[key]
    })
    .sort(sortByOrder)
}

export function getControlValue (optionId, question) {
  const option = question.options.find(option => {
    return option.id === optionId
  })
  if (option) {
    return option.value
  }
}

export function buildTemplateFromQuestionnaire (questionnaire) {
  if(!questionnaire){return null}
  let { ques_sec } = questionnaire
  const sections = ques_sec.map(qs => {
    const section = qs.section
    // Section gets its order from the questionnaire_section
    // relationship so that when a section is in multiple
    // questionnaires, their order in that questionnaire is
    // relative to the questionnaire, and not the same for one
    // section across all of it's questionnaires
    section.order = qs.order
    return section
  })
  const questions = ques_sec.reduce((acc, cur) => {
    const {section} = cur
    return acc.concat(section.questions)
  }, [])
  .map(q => {
  // Order question options here (cannot be done in query due to Objection.js limitations on RelationThrough joins)
    q.options = q.options.sort((a,b) => a.order - b.order)
    return q
  })

  const sectionLookUp = sections.reduce(reduceSections, {})
  const questionLookUp = questions.reduce(reduceQuestions, {})
  for (let question of questions) {
    // Generate field names
    question.field_name = generateFieldNameForQuestion(question)
    question.field_name_description = `${question.field_name}-description`
    question.field_name_date_value = `${question.field_name}-date_value`
    question.field_name_date_value_2 = `${question.field_name}-date_value2`
    // If a question has a control rule we know that it will be hidden to start.
    const {control_question_id, control_option_id} = question
    const isTopLevelQuestion = control_question_id === null
    if (!isTopLevelQuestion) {
      // Set question parent.
      question.parent = questionLookUp[control_question_id]
      // Set question as child on parent.
      if (question.parent) {
        question.parent.children.push(question)
        const control_values_key = getControlValue(control_option_id, question.parent)
        if(control_values_key){
          question.parent.control_values[control_values_key] = control_option_id
        }
      }
    } 
    const section = sectionLookUp[question.section_id]
    if (section) {
      question.section = section
      if (isTopLevelQuestion) {
        // Add only top level questions to the section
        section.questions.push(question)
      }
    }
  }
  return {
    sections: sortedSectionArray(sectionLookUp),
    questions: questionLookUp
  }
}

// Returns a copy of values where all child questions
// that have no value have inherited their parents' 's'
// Only 's' will be inherited.  For example: if a grandparent question is 's'
// and the parent question is 'io', the grandchild question
// will remain unchanged because its direct parent is not an 's'.
// This function will propagate 's' from grandparent question
// to any depth of grandchild questions because it will rerun
// the loop every time a question is updated so as to affect the
// newly updated question's subquestions.
export function inheritSatisfactory (values, template) {
  // Make copy so as to not mutate the argument:
  values = Object.assign({}, values)

  // If a question has no value but it's parent is marked 'satisfatory'
  // that sub question should also be marked satisfactory
  function getParentValue (control_question_id) {
    if (control_question_id !== null && control_question_id !== undefined) {
      const question = template.questions[control_question_id]
      if (!question) {
        return null
      }
      return values[question.field_name]
    } else {
      return null
    }
  }
  let isUpdated = false
  // This loop is designed to loop
  // until all sub questions without values have inherited their parents'
  // 's' values in a way where it cannot get stuck in an infinite loop
  // even in the supposedly impossible scenario where a question is an ancestor
  // to itself
  do {
    isUpdated = false
    for (let q of Object.values(template.questions)) {
      let value = values[q.field_name]
      // if value is falsey and parent is 's', inherit the 's'
      if (!value) {
        const parentValue = getParentValue(q.control_question_id)
        if (parentValue === 's' && q.field_name) {
          // setting isUpdated to true will cause it to loop again
          // this is important because now that this question changed
          // it may have sub questions that need to change too
          isUpdated = true
          values[q.field_name] = 's'
        }
      }
    }
  } while (isUpdated)
  return values
}

export function getAnswers (values, template) {
  const answers = []
  values = inheritSatisfactory(values, template)
  for (let questionId in template.questions) {
    const question = template.questions[questionId]
    const value = values[question.field_name]
    let optionId
    if (value && question.options && question.options.length) {
      const option = question.options.find(option => option.value === value)
      // To allow for the possibility that a sub question could have options
      // that don't include the parents options (eg, a subquestion with Y/N 
      // while the parent is S/IO).  However unlikely, in this event,
      // the question will remain unanswered
      if(option !== undefined){
        optionId = option.id
      }
    }
    /*
      The optionId relates to the chosen option such as 'Yes', 'No', 'S', or 'IO'
      If there is no option ID (question was skipped / the null option 'N/A' was chosen)
      then the answerText should be 'Not Applicable'.

      If there is an optionId ('Yes', 'No', 'S', 'IO' was chosen) then the answerText should remain as it was,
      unless the answerText is 'Not Applicable' in which case it should be changed to 'null' because that means
      the answer was changed from 'Not Applicable' to something else.  This is relevant for editing responses.
    */
    let answerText = values[question.field_name_description]
    answerText = optionId === undefined ? 'Not Applicable' : answerText !== 'Not Applicable' ? answerText : null
    let valueDate = values[question.field_name_date_value] === undefined ? null : values[question.field_name_date_value]
    let valueDate2 = values[question.field_name_date_value_2] === undefined ? null : values[question.field_name_date_value_2]
    if (valueDate && valueDate2) {
      answers.push({
        question_id: question.id,
        value_date: valueDate,
        value_date_2: valueDate2
      })
    } else if (valueDate) {
      answers.push({
        question_id: question.id,
        value_date: valueDate
      })
    } else if (optionId === undefined && answerText === 'Not Applicable') {
      // Do not submit empty answers
      continue
    } else {
      answers.push({
        question_id: question.id,
        option_id: optionId,
        answer_text: answerText
      })
    }
  }
  return answers
}
