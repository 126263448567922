import React from 'react'

function QEWText() {
  return (
  <div>
    <i>
      <h4 className='font-weight-bold'>At no time shall the employee be coached through the demonstration.</h4>
      <h4 className='font-weight-bold'>If any unsafe behaviors are about to be observed, stop the job; if any steps are not demonstrated with proficiency, STOP.</h4>
      <h4 className='font-weight-bold'>Remove the employee's qualification and set up remediation. (Contact TDC to have qualifications removed)</h4>
      <h4 className='font-weight-bold'>Remediation steps must be discussed with the plant manager before any QEW qualification is restored.</h4>
    </i>
  </div>
  )
}

export default QEWText;
