
import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import JDatePickerInternal from '../JDatePickerInternal.jsx'
import ErrorMessage from '../ErrorMessage.jsx'

export default class DatePickerSingle extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hover: null
        }
        this.handleHover = this.handleHover.bind(this)
    }
    handleHover(date) {
        this.setState({ hover: date })
    }
    render() {
        const { label, error, value, onChange, name } = this.props
        const { hover } = this.state
        return <div className='row'>
            {(label || error) && (
                <div className='col-xs-12' data-cy='error-message'><label htmlFor={name}>{label || 'Date'}</label><ErrorMessage message={error}></ErrorMessage></div>
            )}
            <JDatePickerInternal
                {...this.props}
                onChange={date => {
                    typeof onChange === 'function' && onChange(date)
                }}
                onHoverDate={this.handleHover}
                selectionHighlightStart={value}
                selectionHighlightEnd={value}
                hoverHighlightStart={hover}
                hoverHighlightEnd={hover}
                className={classNames('col-xs-12', { 'has-error': error })}
                cy='date-picker-single'
            />
        </div>
    }
}
DatePickerSingle.propTypes = {
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  clearable: PropTypes.bool,
  value: PropTypes.string,
}