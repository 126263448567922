import React, { useCallback, Component } from 'react'
import PropTypes from 'prop-types'
import debounce from 'debounce-promise'
import classNames from 'classnames'
import AsyncSelect from 'react-select/async'

import WithTooltip from '../../components/WithTooltip.jsx'
import ErrorMessage from '../ErrorMessage.jsx'
import { cog } from '../../api/cognition.js'
import { CustomInput } from '../../components/forms/SuperForm.jsx'

function SelectAsync(props) {
  const { value, error, onChange, labelText, initialValue, tooltip, helpText, loadOptions, placeholder, isMulti, components, minInput,
    disabled = false, isClearable = true, isSearchable = true, shouldDebounce } = props
  const errComponent = error && (<ErrorMessage message={error} />)
  const classes = classNames({
    'form-group': true,
    'has-error': error 
  })
  const tooltipIcon = tooltip && <span className='margin-left-5'>&nbsp;<i className='fa fa-question-circle' /></span>
  const elementId = labelText
  const debouncedLoadOptions = useCallback(
    debounce(loadOptions, 400),
    [loadOptions])
  return (
    <div className={classes}>
      <WithTooltip text={tooltip}>
        {(!!labelText || !!tooltipIcon) && (<label htmlFor={elementId}>{labelText}{tooltipIcon}</label>)}
        {errComponent}
      </WithTooltip>
      <AsyncSelect
        defaultInputValue={initialValue}
        inputId={elementId}
        isDisabled={disabled}
        classNamePrefix={'react-select'}
        isClearable={isClearable}
        loadingMessage={() => 'Loading...'}
        noOptionsMessage={({ inputValue }) => {
          if (minInput !== undefined && inputValue.length < minInput) {
            return <div>Enter {minInput} or more characters to search</div>
          } else {
            return <div>No Options</div>
          }
        }}
        // If there is no required minimum input before searching, show results immediately:
        defaultOptions={minInput === undefined}
        isMulti={isMulti}
        isSearchable={isSearchable}
        // components used in SelectPersons
        components={components}
        placeholder={placeholder}
        loadOptions={(input) => {
          // Prevent fetch from firing unless the search criteria meets the mininumInput
          if (minInput !== undefined && input.length < minInput) {
            // Resolve to undefined so that the noOptionsMessage will show
            return Promise.resolve()
          } else {
            return shouldDebounce ? debouncedLoadOptions(input) : loadOptions(input)
          }
        }}
        // The clear value must be either [] or null because undefined represents an uncontrolled component
        // and if the component is supposed to be controlled, setting the value to undefined, will make it act
        // like an uncontrolled component and it may still hold onto internal state that it shouldn't be holding on to
        // (for example when 'resetting' form state)
        value={value || (isMulti ? [] : null)}
        onChange={val => {
          // The clear value must be either [] or null because undefined represents an uncontrolled component
          const protectedValue = val || (isMulti ? [] : null)
          onChange(protectedValue)
        }} />
      {helpText &&
        <span className='help-block help-block-sm-text'>{helpText}</span>
      }
    </div>)
}

SelectAsync.propTypes = {
  labelText: PropTypes.string,
  placeholder: PropTypes.string,
  loadOptions: PropTypes.func,
  isMulti: PropTypes.bool,
  tooltip: PropTypes.string,
  helpText: PropTypes.string,
  components: PropTypes.object,
  // The minimum number of characters required before it will fetch data
  minInput: PropTypes.number,
  // Determines if the search should be debounced.
  // When options only need to be asyncronously fetched once, like in the 
  // case of business units, don't debounce, because it will make
  // the UX slower and will not help the server because the server doesn't
  // get a request every time the user changes the search; however,
  // if the server has to be queried for every change is search input,
  // such as in SelectPersons, then it should be debounced.
  shouldDebounce: PropTypes.bool,
  // if isMulti value is an array, if !isMulti value is an object
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
}

export default SelectAsync

// Populates items from an endpoint and automatically assigns labels
// based on prop labelKey
export class SuperFormSelectAsyncGeneric extends Component {
  constructor(props){
    super(props)
    const {endpoint, labelKey} = this.props
    this.itemsPromise = cog.get(endpoint).then(xs => xs.map(x => ({value: x.id, label: x[labelKey]})))
    this.loadOptions = this.loadOptions.bind(this)
  }
  loadOptions(query){
    return this.itemsPromise.then(items => {
      return items.filter(item => {
        const splitQuery = query.toLowerCase().split(' ')
        for(let i = 0; i < splitQuery.length; i++){
          const queryPart = splitQuery[i]
          // If any part of the label doesn't match the queryPart,
          // exclude the abbrev from the filter
          if(item.label.toLowerCase().indexOf(queryPart) === -1){
            return false
          }
        }
        return true
      })
    })
  }
  render() {
    return <CustomInput {...this.props} loadOptions={this.loadOptions} component={SelectAsync}/>
  }
}
SuperFormSelectAsyncGeneric.propTypes = {
  endpoint: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  // name is required so that SuperForm can associate it's value with a name in state
  name: PropTypes.string.isRequired
}