import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import QuestionDateSelect from './QuestionDateSelect.jsx'
import QuestionRadioSelect from './QuestionRadioSelect.jsx'
import DescriptionTextbox from '../../components/inputs/DescriptionTextbox.jsx'
import QuestionTwoDatesSelect from './QuestionTwoDatesSelect.jsx'
import QEWText from './QEWText.jsx'

class Question extends Component {
  constructor(props){
    super(props)
    this.state = {
      // The chosen option id, used to manage sub question visibility, DescriptionTextbox
      // visibility, and parent stored answer info
      option_id:null,
      // The chosen option value, used to manage the QuestionRadioSelect as a
      // controlled component with the value prop
      value:null,
      // The value for the date ranges.
      value_date: null,
      value_date_2: null,
      // The chosen option answer_text
      answer_text:undefined
    }
    this.handleOnChangeOption = this.handleOnChangeOption.bind(this)
    this.handleOnChangeDate = this.handleOnChangeDate.bind(this)
    this.handleOnChangeSecondDate = this.handleOnChangeSecondDate.bind(this)
    this.sendInfoToParent = this.sendInfoToParent.bind(this)
  }
  componentDidMount(){
    this.prefillInitialValues()
  }
  componentDidUpdate(prevProps){
    const {initialValues} = this.props
    if(prevProps.initialValues !== initialValues){
      this.prefillInitialValues()
    }
  }
  prefillInitialValues() {
    const {initialValues, question} = this.props
    if(initialValues && initialValues.answersObj){

      const initialValue = initialValues.answersObj[question.id]
      if(initialValue){
        const {option_id, answer_text, value_date, value_date_2} = initialValue
        const option = question.options.find(o => o.id === option_id)
        // Since date ranges do not have option id's we need to check if a option exists. 
        if (!option) {
          this.setState({option_id, value_date, value_date_2, answer_text}, this.sendInfoToParent)
        } else {
          this.setState({option_id, value:option.value, value_date, value_date_2, answer_text}, this.sendInfoToParent)
        }
      }
    }
  }
  componentWillUnmount(){
    const { question, clear } = this.props
    if(clear){
      clear(question.id)
    }
  }
  subQuestions () {
    const { question, errors, initialValues } = this.props
    const {option_id} = this.state
    if(!option_id){
      return []
    }
    const questions = !question.children ? [] : question.children.sort((a, b) => a.order - b.order).map(childQ => {
      if(childQ.control_option_id === option_id){
        return (<Question key={`${question.id}-${childQ.id}`}
          // Drill parent onChange all the way down for all sub questions so that they can report
          // their option selections
          onChange={this.props.onChange}
          initialValues={initialValues}
          clear={this.props.clear}
          question={childQ}
          errors={errors}
           />)
      }else {
        return null
      }
    }).filter(question => question !== null)
    return questions
  }

  descriptionInput () {
    const { question } = this.props
    if (!question.description_triggering_option_ids || question.description_triggering_option_ids.length === 0) { return null }
    // Hide the description input box if an option (ex: s/io) has not yet
    // been chosen for this question.  This ensures that the description box
    // doesn't show for "n/a"
    const selectedOption = question.options.find(o => o.id === this.state.option_id)
    if (!selectedOption || question.description_triggering_option_ids.indexOf(selectedOption.id) === -1) { return null }
    return (
      <div className='col-sm-12 question-description-input'>
        <DescriptionTextbox
          value={this.state.answer_text}
          onChange={value => {
            this.setState({ answer_text:value }, this.sendInfoToParent)
          }}
          name={question.field_name_description}
          placeholder={question.description}
        />
      </div>
    )
  }
  handleOnChangeOption(optionValue){
    const {question} = this.props
    // Set state locally to manage sub questions
    const selectedOption = (question ? question.options : []) 
      .find(o => o.value === optionValue)
    const option_id = selectedOption ? selectedOption.id : null
    this.setState({ value:optionValue, option_id }, this.sendInfoToParent)

  }

  handleOnChangeDate(value_date) {
    const {question} = this.props
    this.setState({ question_id: question.id, value_date}, this.sendInfoToParentDate) 
  }

  handleOnChangeSecondDate(value_date_2) {
    const {question} = this.props
    this.setState({ question_id: question.id, value_date_2}, this.sendInfoToParentDate) 
  }

  sendInfoToParentDate() {
    const {question, onChange} = this.props
    const { value_date, value_date_2 } = this.state
    if(onChange){
      onChange({question_id:question.id, value_date, value_date_2})
    }
  }

  sendInfoToParent(){
    const {question, onChange} = this.props
    const {option_id, answer_text} = this.state
    if(onChange){
      onChange({question_id:question.id, option_id, answer_text})
    }
  }

  render () {
    const { question, errors } = this.props
    const classes = classNames({
      'row': true,
      'question-row': true,
      'sub-question-row': question.parent !== null
    })
    const subQuestions = this.subQuestions()
    return (
      <div className={classes}>
        {question.header && <h4>{question.header}</h4>}
        {question.id === 888 && <QEWText/>}
        <div className='col-md-12 question-input'>
          {(question.question_style === 'radio_buttons' || question.question_style === 'dropdown') && (
            <QuestionRadioSelect
              onChange={this.handleOnChangeOption}
              value={this.state.value || ''}
              question={question}
              isRequired={typeof question.isRequired !== 'undefined' ? question.isRequired : (question.section && question.section.number_required === -1 && question.parent === null)}
              error={errors ? errors[question.id] : undefined}
            />
          )} 
          {question.number_of_dates_displayed === 'One' && question.question_style === 'date' && (
            <QuestionDateSelect
              onChange={this.handleOnChangeDate}
              value={this.state.value_date || ''}
              question={question}
              isRequired={typeof question.isRequired !== 'undefined' ? question.isRequired : (question.section && question.section.number_required === -1 && question.parent === null)}
              error={errors ? errors[question.id] : undefined} 
            />
          )}
          {question.number_of_dates_displayed === 'Two' && question.question_style === 'date' && (
            <QuestionTwoDatesSelect
              onChange={this.handleOnChangeDate}
              onChangeSecondRange = {this.handleOnChangeSecondDate}
              valueFirstDate={this.state.value_date || ''}
              valueSecondDate={this.state.value_date_2 || ''}
              question={question}
              isRequired={typeof question.isRequired !== 'undefined' ? question.isRequired : (question.section && question.section.number_required === -1 && question.parent === null)}
              error={errors ? errors[question.id] : undefined}
            />
          )}
        </div>
        {this.descriptionInput()}
        {subQuestions.length > 0 ? (
          <div className='col-sm-12 sub-questions'>
            {subQuestions}
          </div>
        ) : null}
      </div>
    )
  }
}

Question.propTypes = {
  question: PropTypes.object.isRequired,
  // Used to clear the question answer data where it is stored in an ancestor component
  clear:PropTypes.func,
  // Used to recorded the changed state of the question's answer data in an ancestor component
  onChange:PropTypes.func,
  errors:PropTypes.object,
  // Usually a question being required is managed by the question's section, but allow this isRequired prop
  // to manually override
  isRequired: PropTypes.bool
}

export default Question
