import React from 'react'
import classNames from 'classnames'
import DatePickerSingle from './DatePickerSingle.jsx'
import PropTypes from 'prop-types'

class DescriptionDate extends React.Component {
  render () {
    const { error, name, onChange, placeholder, value } = this.props
    const classes = classNames({
      'form-group': true,
      'has-error': error
    })
    return (
      <div className={classes}>
        <DatePickerSingle className='form-control' id={name} onChange={onChange} placeholder={placeholder} value={value}/>
      </div>
    )
  }
}

DescriptionDate.propTypes = {
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
}

export default DescriptionDate
