import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import SelectPersons from '../inputs/SelectPersons.jsx'
import { SuperForm, Form, CustomInput } from '../../components/forms/SuperForm.jsx'

class AddDataAccess extends Component {
  constructor(props) {
    super(props)
    this.state = {
      values: {},
      errors: {}
    }
  }

  render() {
    const { user } = this.props
    const containerClass = classNames({
      'col-md-10': user.admin,
      'col-md-7': !user.admin
    })
    const inputClass = classNames({
      'col-md-5': user.admin,
      'col-md-8': !user.admin
    })
    const buttonClass = classNames({
      'col-md-2': user.admin,
      'col-md-3': !user.admin,
      'hidden-xs': true,
      'hidden-sm': true
    })
    return (
      <SuperForm onSubmit={this.props.onSubmit} clearOnSubmit>
        <Form>
          <div className={containerClass}>
            <div className='panel panel-default'>
              <div className='panel-heading'>
                <strong>Add Data Access</strong>
              </div>
              <div id='add-data-access' className='panel-collapse collapse in' role='tabpanel'>
                <div className='panel-body'>
                  <div className='form-group add-data-fields'>
                    {user.admin &&
                      <div className={inputClass}>
                        <CustomInput
                          component={SelectPersons}
                          name={'authorized_access_to_user'}
                          isMulti={false}
                          labelText='User granting access*'
                          isRequired
                        />
                      </div>
                    }
                    <div className={inputClass}>
                      <CustomInput
                        component={SelectPersons}
                        name={'requested_access_user'}
                        isMulti={false}
                        labelText='User receiving access*'
                        isRequired
                      />
                    </div>
                    <div className={buttonClass}>
                      <label>&nbsp;</label>
                      <button className='col-xs-12 btn btn-primary' type='submit'>
                        <span className='fa fa-plus' />  Add</button>
                    </div>
                  </div>
                  <div className='form-group add-data-btn col-xs-12 hidden-md hidden-lg'>
                    <button className='col-xs-12 btn btn-primary'><span className='fa fa-plus' />  Add</button>
                  </div>
                  <i className='pull-left'>
                    The use of any private information that may be contained in an observation should follow all DTE and federal privacy policies.
                    "Data Access" is set-up where access granted to another employee includes all the employees that directly or indirectly report to them.
                    Responsibility rests with the employee that granted access as well as the employee receiving access to ensure that policies are upheld.
                  </i>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </SuperForm>
    )
  }
}

AddDataAccess.propTypes = {
  handleSubmit: PropTypes.func,
  user: PropTypes.object.isRequired
}

export default AddDataAccess
