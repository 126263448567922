import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import _get from 'lodash.get'
import SafeHTML from '../../components/SafeHTML.jsx'
import WithTooltip from '../../components/WithTooltip.jsx'

class Question extends Component {

  generateTooltip () {
    const { question } = this.props
    if (question.help_text === '') { return null }
    return (
      <div className='col-md-12'>
        <WithTooltip text={question.help_text}>
          <i className='question-info-sign fa fa-info-circle'/>
        </WithTooltip>
      </div>
    )
  }

  getAnswer () {
    const { question, response } = this.props
    const answer = response.answers.find(answer => {
      return answer.question_id === question.id
    })
    if (answer === undefined || answer.answer_text === 'Not Applicable') {
      return
    }
    let text
    if (answer.answer_text) {
      text = (
        <div className='row'>
          <div className='col-md-12'>
            <div className='panel panel-default panel-question-text'>
              <div className='panel-body'>
                <samp>{answer.answer_text}</samp>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (answer.value_date_2 && answer.value_date) {
      text = (
        <div className='row'>
          <div className='col-md-12'>
            <div className='panel panel-default panel-question-text'>
              <div className='panel-body'>
                <samp>{moment(answer.value_date).format('dddd, MMMM Do, YYYY')}<br/>{moment(answer.value_date_2).format('dddd, MMMM Do, YYYY')}</samp>
              </div>
            </div>
          </div>
        </div>
      )
    } else if (answer.value_date) {
      text = (
        <div className='row'>
          <div className='col-md-12'>
            <div className='panel panel-default panel-question-text'>
              <div className='panel-body'>
                <samp>{moment(answer.value_date).format('dddd, MMMM Do, YYYY')}</samp>
              </div>
            </div>
          </div>
        </div>
      )
    }
    let option = '---'
    if (answer.option) {
      if(_get(answer, 'question.option_text_masquerade') === 'identified'){
        if(answer.option.value === 's'){
          option = <strong>Identified</strong>
        }else if(answer.option.value === 'io'){
          option = <strong>Not Identified</strong>
        }
      }else {
        option = (<strong>{answer.option.text}</strong>)
      }
    }
    const rowClasses = classNames({
      // Add life-critical class to only top level life critical questions
      'life-critical': _get(question, 'section.name', '') === 'Life Critical' && question.control_question_id === null,
      'col-xs-8': true,
      'col-sm-10': true,
      'question-text': true
    })
    return (
      <div className='col-md-12'>
        <div className='row'>
          <div className={rowClasses}>
            <SafeHTML markdown={question.text} />
          </div>
          <div className='col-xs-3 col-sm-2 text-right'>
            {option}
          </div>
        </div>
        {text}
      </div>
    )
  }

  subQuestions () {
    const { question, response } = this.props
    const questions = !question.children ? [] : question.children.sort((a, b) => a.order - b.order).map(question => {
      const answer = response.answers.find(answer => {
        return answer.question_id === question.id
      })
      if (answer === undefined || answer.answer_text === 'Not Applicable') {
        return null
      }
      return (<Question key={question.id}
        question={question}
        response={response} />)
    }).filter(question => question !== null)
    if (questions.length > 0) {
      return (
        <div className='col-sm-12 sub-questions'>
          {questions}
        </div>
      )
    }
  }

  render () {
    const { question } = this.props
    const answer = this.getAnswer()
    const classes = classNames({
      'row': true,
      'question-row': true,
      'sub-question-row': question.parent !== null
    })
    return (
      <div className={classes}>
        {this.generateTooltip()}
        {answer}
        {this.subQuestions()}
      </div>
    )
  }
}

Question.propTypes = {
  question: PropTypes.object.isRequired,
  response: PropTypes.object.isRequired
}

export default Question
