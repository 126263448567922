import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _get from 'lodash.get'
import WithTooltip from '../../components/WithTooltip.jsx'
import EmployeeNameByAzureId from '../../components/EmployeeNameByAzureId.jsx'

class ResponseInfo extends Component {

  static observedDetails (employees) {
    if (employees.length === 0) {
      return (
        <div>
          <p>Anonymous</p>
        </div>
      )
    }
    return (
      <div>
        {employees.map(employee => {
          return (
            <p key={employee.user.id}>
              {employee.user.display_name}
            </p>
          )
        })}
      </div>
    )
  }

  static getCreatedByDisplay (createdBy) {
    if (createdBy !== null) {
      return createdBy.display_name
    }
    return 'Anonymous'
  }

  static locationDeatils (location) {
    if (!location) {
      return (<p />)
    }
    return (<p>{location.name}</p>)
  }

  render () {
    const { response } = this.props
    // The following logic will display both the sublocation and the free form if they both exist,
    // or only one if only one exists:
    const locale = [response.sublocation, response.sublocation_free_form].filter(s => !!s).join(' - ')
    return (
      <div className='row'>
        <div className='col-md-12'>
          <div className='page-header'>
            <h4>{response.questionnaire.name}</h4>
          </div>
        </div>
        <div className='col-md-12'>
          <div className='form-group'>
            <label>ID</label>
            <p>{response.id}</p>
          </div>
        </div>
        {response.category && (
          <div className='col-md-12'>
            <div className='form-group'>
              <label>Type</label>
              <p>{response.category.name}</p>
            </div>
          </div>
        )}
        {response.is_free_form ? (
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>Name</label>
                  <p>
                    {response.free_form_observed_name === '' ? 'Anonymous' : response.free_form_observed_name }
                  </p>
                </div>
              </div>
              { response.company &&
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>Company</label>
                  <p>
                    { response.company.name }
                  </p>
                </div>
              </div>
              }
            </div>
          </div>
        ) : (
          <div className='col-md-12'>
            <div className='form-group'>
              {response.questionnaire && response.questionnaire.name === 'Pre-Job Brief'
                ? (<label>Individual(s) Attended</label>)
                : (<label>Individual(s) Observed</label>)}
              {ResponseInfo.observedDetails(response.employeesObserved)}
            </div>
            {_get(response, 'extra.set_personnel') && ( <div className='form-group'>
                <label>SET Personnel</label>
                <p><EmployeeNameByAzureId azure_id={_get(response, 'extra.set_personnel')}/></p>
            </div>)}
          </div>
        ) }
        <div className='col-md-12'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='form-group'>
                <label>
                  <WithTooltip inlineBlock position={'upper-left'} text='Business Unit of the observed.'>
                    <span>Business Unit <i className='fa fa-question-circle' /></span>
                  </WithTooltip>
                </label>
                <p>{response.organizationObserved.name}</p>
              </div>
            </div>
            {_get(response, 'location_storm_reports') && (<div className='col-md-8'>
              <div className='form-group'>
                <label>Person's Reports to Location</label>
                <p>{_get(response, 'location_storm_reports.name')}</p>
              </div>
            </div>)}
            <div className='col-md-4'>
              <label>
                <WithTooltip inlineBlock position={'upper-left'} text='Where the observation took place.'>
                  <span>Location <i className='fa fa-question-circle' /></span>
                </WithTooltip>
              </label>
              {ResponseInfo.locationDeatils(response.locationObserved)}
            </div>
            {locale && (<div className='col-md-4'>
              <label>
                Locale
              </label>
              <p>
                {locale}
              </p>
            </div>)}
          </div>
        </div>
        <div className='col-md-12'>
          <div className='row'>
            <div className='col-sm-4'>
              <div className='form-group'>
                <label>Observed By</label>
                <p>{ResponseInfo.getCreatedByDisplay(response.createdBy)}</p>
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='form-group'>
                <label>Observed On</label>
                <p>{moment(response.observed_at).utc().format('dddd, MMMM Do, YYYY')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-12'>
          <div className='row'>
            {response.equipment && (<div className='col-sm-4'>
              <div className='form-group'>
                <label>Equipment</label>
                <p>{response.equipment}</p>
              </div>
            </div>)}
            {response.task && (<div className='col-sm-4'>
              <div className='form-group'>
                <label>Task</label>
                <p>{response.task}</p>
              </div>
            </div>)}
            {response.time_of_day && (<div className='col-sm-4'>
              <div className='form-group'>
                <label>Time of Day</label>
                <p>{response.time_of_day}</p>
              </div>
            </div>)}
          </div>
        </div>
        {!!response.extra && (
        <div className='col-md-12'>
          <div className='row'>
            {_get(response, 'extra.time_exact') && (<div className='col-sm-4'>
              <div className='form-group'>
                <label>Time</label>
                <p>{_get(response, 'extra.time_exact')}</p>
              </div>
            </div>)}
          </div>
        </div>

        )}
      </div>
    )
  }
}

ResponseInfo.propTypes = {
  response: PropTypes.object.isRequired
}

export default ResponseInfo
