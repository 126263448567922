// This file and related auth files are adapted and extended from https://github.com/Azure-Samples/ms-identity-javascript-v2.git
import Cookies from 'js-cookie'
import * as Sentry from '@sentry/browser'
import { parse } from 'query-string'

import store from '../store.js'
import { loginUser, logoutUser } from '../actions/auth'
import { cog } from '../api/cognition'
import * as AuthToast from '../containers/AuthToast.jsx'

export default class OAuthAgent {
  constructor(MSAL) {
    this.signinUser = this.signinUser.bind(this)
    this.updateTokenFromMSALResponse = this.updateTokenFromMSALResponse.bind(this)
    this.refreshTokenPromise = null
    // MSAL is marked with a preceeding understore to signify that it is private
    // All interactions with this MSAL object should occur in this 
    // file to ensure that the OAuthAgent's state is updated appropriately after interacting with MSAL
    this._MSAL = MSAL
  }
  updateTokenFromMSALResponse(MSALResponse){
    console.debug('oauth: updateTokenFromMSALResponse')
    if (MSALResponse) {
      this.accessToken = MSALResponse.accessToken
      this.expiresOn = MSALResponse.expiresOn
      // When the accessToken is set, hide the auth toast since the user is now logged in and the oauth token
      // is no longer missing or expired
      AuthToast.hide()
    } else {
      const err = new Error('oauth: No MSALResponse passed to updateTokenFromMSALResponse')
      console.error(err)
      Sentry.captureException(err)
      throw err
    }
  }
  signinUser() {
    console.debug('oauth: signinUser')
    if (!this.invalid()) {
      // Get database state of signed in user
      return cog.getUser().then(user => {
        if (user){
          Sentry.configureScope((scope) => {
            scope.setUser({
              email:user.email,
              id: user.id,
              azure_id: user.azure_id,
              role: user.role 
            })
          })
          console.debug('Signing in user...')
          store.dispatch(loginUser({user}))
          return user
        }else {
          console.debug('No user returned from cog.getUser(), signing out user...')
          store.dispatch(logoutUser())
        }
      }).catch(error => {
        console.error('cog.getUser error:', error)
        Sentry.captureException(new Error('Unable to reach server via getUser(); Cognition may be down'))
        store.dispatch(logoutUser('Unable to reach server.  Please try again in a few minutes.'))
      })
    }else {
      console.debug('oauth: Cannot sign in user because token is invalid')
    }
  }
  login() {
    console.debug('oauth: login')
    if (!Cookies.get('next')) {
      const queryStrings = parse(location.search)
      // Prevent nested 'next' paths: use queryString next if it already exists
      // Otherwise, use pathname + search for the 'next' url to redirect to
      const next = queryStrings && queryStrings.next || (location.pathname + location.search)
      Cookies.set('next', next, { expires: 60000, sameSite: 'Strict', secure: true })
      console.debug('Default login redirect via setting "next" cookie to', next)
    }

    const loginPromise = this._MSAL.loginRedirect(tokenRequest)

    return loginPromise
      .catch(error => {
          console.error(error.errorCode, error)
          if(error.errorCode === 'interaction_in_progress'){
            console.log('You have another login prompt active on a different tab.  Please close or complete the other tab\'s login prompt before attempting to log in on this tab.')
            // Temporarily protect against being unable to authenticate when interaction.status is set to
            // interaction_in_progress.  This will be officially fixed in the next msal-browser release
            // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2614
            localStorage.removeItem(`msal.98c9fe41-1e2a-4d0a-89e7-41c257b363b0.interaction.status`)
            localStorage.removeItem(`msal.d60db8b8-cb38-4d4f-8c0e-80ffe1029736.interaction.status`)
          }
          // Solution mentioned here: 
          //https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2617#issuecomment-729848216
          // Calling handleRedirectPromise as a response to 'interaction_in_progress' error clears out
          // the interaction in progress and sets it up to try again.
          // Attempt loginRedirect as plan-b for other msal errors such as 'user_cancelled' and others
          return this._MSAL.handleRedirectPromise().catch(console.error).then(() => this._MSAL.loginRedirect(tokenRequest)) 
      });

  }
  getLatestAccount(){
    const accounts = this._MSAL.getAllAccounts()
    return accounts && accounts.length ? accounts[0] : null
  }
  handleRedirectPromise(){
    console.debug('oauth: handleRedirectPromise')
    return this._MSAL.handleRedirectPromise().then(MSALResponse => {
      // MSALResponse will exist if the user is now on this page as a result of a redirect
      // from Microsoft's authentication page
      if(MSALResponse){
        this.updateTokenFromMSALResponse(MSALResponse)
        return this.signinUser()
      } else {
        // If MSALResponse does NOT exist, it's probable that the user directed themself to
        // the login page initially, in that case attempt to login silently.
        // If silent login fails, catch it gracefully and allow the user to click the 'login'
        // button manually to start the redirect login flow.
        console.debug('oauth: No MSALResponse, attempt login silently as backup...')
        return this.refreshTokenIfNeeded()
          .then(() => {
            // Since this flow is logging in silently on mount, that means a cognition user is not currently
            // and should be fetched with the newly, silently aquired token
            return this.signinUser()
          })
          .catch(e => {
            console.debug('Supressed silent login error: ', e.message)
          })
      }
    })
  }

  // refreshTokenIfNeeded()
  // Returns a promise that is fulfilled after a silent token refresh attempt (regardless of success or failure)
  // When the token is invalid and when this function is called multiple times in quick succession, 
  // the first call will begin the async refresh and the subsequent calls will immediately return the promise of the 
  // first call, so that when the first resolves, they will all resolve without each having to make a request to the 
  // Microsoft endpoint.  This is done using `refreshTokenPromise`.
  // After the refresh is complete, `refreshTokenPromise` is set back to null, which is critical, so that when the
  // newly aquired token expires, this whole process (of only the first call making the request and the others waiting
  // for the new token) can begin anew.
  refreshTokenIfNeeded() {
    if(this.invalid()){
      if(this.refreshTokenPromise){
        console.debug('oauth: Token refresh is already in progress, awaiting it\'s fulfillment...')
        return this.refreshTokenPromise
      }
      console.debug('oauth: refreshing token...')
      const account = this.getLatestAccount() 
      if(!(account)){
        const err = new Error('No existing MSAL session, unable to sign in')
        console.debug('oauth: refreshTokenIfNeeded silent err: ', err.message)
        return Promise.reject(err)
      }
      const request = Object.assign(tokenRequest)
      request.account = account
      this.refreshTokenPromise = this._MSAL.acquireTokenSilent(request)
        .then(this.updateTokenFromMSALResponse)
        .then(() => {
          // This must execute AFTER this.updateTokenFromMSALResponse so that this.invalid() now returns false
          // before this.refreshTokenPromise is set to null.
          console.debug('oauth: Refresh completed; clearing this.refreshTokenPromise')
          // Clear refreshTokenPromise so that when NEW token expires, refreshTokenPromise won't already be fulfilled
          this.refreshTokenPromise = null
        })
        .catch(e => {
          console.debug('acquireTokenSilent/refreshTokenIfNeeded was unable to sign in.', e)
          // Clear refreshTokenPromise because the promise rejected, and this property is only valuable
          // if the refresh is in progress with the potential to resolve in the future
          this.refreshTokenPromise = null
          throw e
        })
      return this.refreshTokenPromise
    } else {
      // Token is already valid, no need to refresh
      return Promise.resolve()
    }
  }
  logout() {
    console.debug('oauth: logout')
    /**
     * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
     */
    this._MSAL.logout()
  }
  invalid() {
    // expiresOn - Javascript Date object representing relative expiration of access token
    // extExpiresOn - Javascript Date object representing extended relative expiration of access token in case of server outage
    if (!this.accessToken || !this.expiresOn) {
      console.debug(`oauth: accessToken: ${this.accessToken}, expiresOn: ${this.expiresOn}`)
      return true
    }
    const expired = this.expired()
    const debugToken = `oauth: ${!!this.accessToken ? 'has accessToken' : 'no accessToken'}, ${expired ? 'is Expired' : 'valid until ' + this.expiresOn}`
    if(this.lastDebugToken !== debugToken){
      console.debug(debugToken)
    }
    this.lastDebugToken = debugToken
    return expired
  }

  authenticated() {
    return !!this.accessToken
  }

  expired(){
    return this.expiresOn <= new Date()
  }
}

/**
* Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
* https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
*/
const tokenRequest = {
  scopes: [`api://${AZURE_APP_AUTH_CLIENT_ID}/default`],
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};