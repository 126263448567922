import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import queryString from 'query-string'
import moment from 'moment'
import _get from 'lodash.get'
import { toast } from 'react-toastify'
import * as Sentry from '@sentry/browser'

import '../../css/observation.css'
import { cog } from '../../api/cognition'
import { FormDateLimitContext } from '../../contexts.js'
import { sublocations } from '../../components/inputs/SelectSublocation.jsx'
import * as form from '../../services/form'
import ErrorOverlay from '../../components/ErrorOverlay.jsx'
import ErrorBox from '../../components/ErrorBox.jsx'
import LoadingOverlay from '../../components/LoadingOverlay.jsx'
import Modal from '../../components/Modal.jsx'
import ObservationForm from './ObservationForm.jsx'
import { ISO_DATE_FORMAT } from '../../constants.js'

const SUCCESS_MESSAGE = 'Your form was submitted successfully!'

class Observation extends Component {
  constructor(props) {
    super(props)
    const params = queryString.parse(location.search)
    this.state = {
      // lastFormValues is used for carrying over values between questionnaires
      // if questionnaires are linked. See linked_questionnaire_id in database
      initialValues: window.lastFormValues || {
        date: moment().format(ISO_DATE_FORMAT),
        observed: [],
        location: null,
        company: null,
        businessUnit: null,
        type: null,
        comments: '',
        anonymous: false,
        freeForm: false
      },
      template: null,
      questionnaire: null,
      // Default start date limit to the past 7 days
      formStartDateLimit: moment().subtract(7, 'days'),
      // Default end date limit to today
      formEndDateLimit: moment(),
      submitting: false,
      loading: false,
      editId: params.edit && parseInt(params.edit, 10),
      authError: null

    }
    // resumeId is used to associate the current in-state observation with a "response_unfinished" row in the database
    // It is needed in order to:
    // 1. Delete a response_unfinished row when the response is finally and succesfully submitted
    // 2. Restore the observation state after a user is redirected to reauthenticate due to a 401 on submit because
    // of an expired auth token
    // 3. Discarding a saved observation (row in the response_unfinished table)
    // Note, after this point always use this.setResumeId to update the resumeId so it also updates the query-param
    this.resumeId = params.resumeId && parseInt(params.resumeId, 10)
    this.setResumeId = this.setResumeId.bind(this)

    // Now that lastFormValues have been used to carry data between linked forms, remove it so that
    // it won't carry over to the next form
    delete window.lastFormValues
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSaveProgress = this.handleSaveProgress.bind(this)
    this.getSaveInfo = this.getSaveInfo.bind(this)
    this.checkSections = this.checkSections.bind(this)
    this.handleModal = this.handleModal.bind(this)
    this.navToHomepage = this.navToHomepage.bind(this)
    this.discardObservation = this.discardObservation.bind(this)
  }
  componentDidMount() {
    // Make a nonce that will assist in saving unfinished responses for this user
    this.nonce = new Date().getTime()
    const slug = _get(this.props, 'match.params.slug', null)
    // Get questionnaire
    cog.getQuestionnaireBySlug(slug).then(questionnaire => {
      const template = form.buildTemplateFromQuestionnaire(questionnaire)
      this.setState({ template, questionnaire })
    }).catch(e => {
      // If the questionnaire can't be populated, the user cannot fill out the form
      Sentry.captureException(e)
      if (e.status === 401) {
        console.error(e)
        this.setState({ authError: e })
      } else {
        console.error('Redirecting to / due to error', e)
        location.replace('/')
      }
    })

    function getIdTextPair(multiSelectResult) {
      const value = _get(multiSelectResult, 'id')
      const label = _get(multiSelectResult, 'name')
      if (typeof value === 'undefined') {
        return undefined
      }
      return {
        value,
        label
      }
    }
    const params = queryString.parse(location.search)
    if (params.quickActionAzureId) {
      this.setState({ loading: true })
      cog.getUser(params.quickActionAzureId).then(user => {
        // Quick actions jump to a form with a user's name prefilled in the 'Observed section'
        this.setState(prevState => ({
          loading: false,
          initialValues: Object.assign({}, prevState.initialValues, {
            observed: [
              {
                value: user.azure_id,
                label: user.display_name,
                title: user.title,
                email: user.email

              }
            ]
          })
        }))
      })
    }
    let rehydratePromise
    if (this.state.editId !== undefined) {
      // For editing completed responses
      this.setState({ loading: true })
      rehydratePromise = cog.getResponsesById(this.state.editId)
    } else if (this.resumeId !== undefined) {
      // For resuming unfinished responses
      this.setState({ loading: true })
      rehydratePromise = cog.get('/responses/unfinished/' + this.resumeId)
        .catch(() => {
          // If the response_unfinished row corresponding to the resumeId doesn't exist,
          // this endpoint will error with 404, in that case, navigate home
          this.navToHomepage()
          toast.info('This saved observation has either been intentionally removed or successfully submitted.')
        })
    }

    if (rehydratePromise) {
      rehydratePromise.then(response => {
        this.setState({ loading: false })
        if (!response) {
          return
        }
        // Limit edit date to earliest as 7 days before the created_at date.
        // This is because the observed_at date can change again and again when editing, but
        // the created_at date always stays the same
        const formStartDateLimit = moment(response.created_at).subtract(7, 'days')
        const formEndDateLimit = moment(response.created_at)
        this.setState({ formStartDateLimit, formEndDateLimit })
        const sublocation = sublocations.find(s => s.label === response.sublocation)
        const initialValues = {
          location: getIdTextPair(response.locationObserved),
          businessUnit: getIdTextPair(response.organizationObserved),
          comments: response.addtl_comments,
          date: moment(response.observed_at).utc().format(ISO_DATE_FORMAT),
          sublocation,
          sublocation_free_form: response.sublocation_free_form,
          // If we are rehydrating from an unfinished response, it will have an anonymous property,
          // if we are rehydrating for an edit, anonymous is determined by the lack of existance 
          // of created_by_user_id
          anonymous: typeof response.anonymous === 'undefined' ? !response.created_by_user_id : response.anonymous,
          observed: [],
          company: getIdTextPair(response.company),
          freeForm: !!response.free_form_observed_name || !!getIdTextPair(response.company),
          free_form_observed_name: response.free_form_observed_name,
          type: getIdTextPair(response.category),
          task: response.task,
          equipment: response.equipment,
          time_of_day: response.time_of_day,
          // storm form only:
          time_exact: _get(response, 'extra.time_exact'),
          // storm form only:
          location_storm_reports: getIdTextPair(_get(response, 'location_storm_reports')),
          answersObj: {}
        }
        if (_get(response, 'extra.set_personnel')) {
          const set_personnel_azure_id = _get(response, 'extra.set_personnel')
          initialValues.set_personnel = {
            value: set_personnel_azure_id
          }
          initialValues.was_set_conducted = 'yes'

        }
        response.answers.forEach(answer => {
          const { question_id, option_id, answer_text, value_date, value_date_2 } = answer
          initialValues.answersObj[question_id] = { question_id, option_id, answer_text, value_date, value_date_2 }
        })
        response.employeesObserved.forEach(employee => {
          const { user } = employee
          initialValues.observed.push({
            value: user.azure_id,
            label: user.display_name,
            title: user.title,
            email: user.email
          })
        })
        this.setState({ initialValues })
      })
    }
  }



  checkSections(answers) {
    const { template } = this.state
    const sectionErrors = {}
    const questionErrors = {}
    // We make sure sections that require some answered get validated.
    const sections = template.sections
    for (let section of sections) {
      if (section.number_required === -1) {
        // all questions are required to be answered
        for (let q of section.questions) {
          const answer = answers[q.id]
          if (!answer) {
            sectionErrors[section.id] = 'All the questions in this section are required.'
            break;
          }
          if (answer.value_date) {
            continue;
          }
          if (!answer.option_id) {
            sectionErrors[section.id] = 'All the questions in this section are required.'
            break;
          }
        }
      } else if (section.number_required > 0 && section.questions.length > 0) {
        let answered = 0
        section.questions.forEach(question => {
          const answer = answers[question.id]
          if (answer && answer.option_id) {
            answered += 1
          }
        })
        if (answered < section.number_required) {
          let pluralText = 'question'
          if (section.number_required > 1) {
            pluralText = 'questions'
          }
          sectionErrors[section.id] = `You must answer at least ${section.number_required} ${pluralText}.`
        }
      }
    }
    // We make sure questions that are showing sub-questions have at least one answered.
    const answersArray = Object.values(answers)
    for (let answer of answersArray) {
      const { question_id, option_id } = answer
      const question = template.questions[question_id]
      // Get children, filter for visible children, map to ids
      const childQuestionIds = (question.children || []).filter(childQ => childQ.control_option_id === option_id).map(childQ => childQ.id)
      const childAnswers = answersArray.filter(a => childQuestionIds.indexOf(a.question_id) !== -1)
      // If the parent has child questions
      if (childQuestionIds.length) {
        // Ensure that at least one of the questions is answered
        let answeredCount = 0
        childAnswers.forEach(answer => {
          if (answer.option_id) {
            answeredCount += 1
          }
        })
        if (answeredCount < 1) {
          questionErrors[question.id] = 'Answer at least one sub-question.'
        }
        // Ensure that if a parent has an IO, that one of it's children has an io
        // ---
        // Note: this may need to be expanded upon in the future because
        // a 'yes' or 'no' question could expand to contain answers with 'io'
        if (option_id === 2) {
          let childHasIO = false
          for (let answer of childAnswers) {
            if (answer.option_id === 2) {
              childHasIO = true
              break
            }
          }
          if (!childHasIO) {
            questionErrors[question.id] = `One of the sub questions must be marked 'IO' for this question to be marked 'IO'`
          }
        }
      }
    }
    if (Object.values(questionErrors).length > 0 || Object.values(sectionErrors).length > 0) {
      this.setState({ sectionErrors, questionErrors })
      return false
    }
    this.setState({ sectionErrors: undefined, questionErrors: undefined })
    return true
  }

  navToHomepage() {
    const { history } = this.props
    history.push('/')
  }
  discardObservation() {
    if (window.confirm(`Are you sure you want to discard this observation?`)) {
      if (!this.resumeId) {
        this.navToHomepage()
      } else {
        cog.delete('/responses/unfinished', { id: this.resumeId })
          .then(() => {
            this.navToHomepage()
          }).catch(e => {
            console.error(e)
            toast.error('There was a problem discarding this observation.')
          })
      }
    }

  }
  processResponse(values = {}, answers = {}) {
    // Spread values (in order to break the reference, because `values` will be mutated later in order 
    // to merge in `answers`) and we don't want `answers` passed in as well
    this.lastFormValues = { ...values }
    const { questionnaire, template } = this.state
    // Add answers to values:
    for (let answer of Object.values(answers)) {
      const question = template.questions[answer.question_id]
      values[question.field_name] = (question.options.find(o => o.id === answer.option_id) || {}).value
      if (answer.answer_text) {
        values[question.field_name_description] = answer.answer_text
      }
      if (answer.value_date) {
        values[question.field_name_date_value] = answer.value_date
      }
      if (answer.value_date_2) {
        values[question.field_name_date_value_2] = answer.value_date_2
      }
    }

    // Make sure anonymous is correct based on questionnaire allowed.
    // This also makes sure presets can never have anon true when is should never be.
    let freeForm = values.freeForm || false
    if (questionnaire.allow_free_form_observed === false) {
      freeForm = false
    }

    let observed = []
    let free_form_observed_name = ''
    if (freeForm === false) {
      observed = values.observed
    } else {
      free_form_observed_name = values.free_form_observed_name ? values.free_form_observed_name.trim() : ''
    }

    let anonymous = values.anonymous || false
    if (questionnaire.allow_anonymous_observer === false) {
      anonymous = false
    }

    // Temporarily store boolean in global scope to denote that user recently submitted an anonymous observation.
    // This is purposefully not managed by react.  It is used for an optional temporary tip.
    window.recentlyMadeAnonObservation = anonymous

    // Set the category from the form values or default it from the questionnaire.
    let typeId
    if (values.type) {
      typeId = values.type.value
    } else {
      typeId = questionnaire.category_id
    }
    const response = {
      // editID applies if user is editing an observation, it will be undefined if user is making a new observation
      id: this.state.editId,
      questionnaire_id: questionnaire.id,
      observed_at: values.date,
      location_observed_id: _get(values, 'location.value'),
      sublocation: _get(values, 'sublocation.label', null),
      sublocation_free_form: _get(values, 'sublocation_free_form', null),
      organization_observed_id: _get(values, 'businessUnit.value'),
      employeesObserved: Array.isArray(observed) ? observed.map(o => ({ azure_id: o.value })) : [],
      addtl_comments: values.comments ? values.comments.trim() : '',
      answers: form.getAnswers(values, template),
      anonymous: anonymous,
      is_free_form: freeForm,
      free_form_observed_name,
      category_id: typeId,
      company_id: values.company && values.company.value,
      task: values.task,
      equipment: values.equipment,
      time_of_day: values.time_of_day,
      extra: {
        // storm form only:
        time_exact: formatTime(values.time_exact),
        // storm form only:
        location_storm_reports: _get(values, 'location_storm_reports.value'),
        // field form only:
        set_personnel: _get(values, 'set_personnel.value')
      }
    }
    return response
  }
  getSaveInfo() {
    return { azure_id: _get(this.props, 'user.profile.azure_id'), nonce: this.nonce, resumeId: this.resumeId }
  }

  handleSaveProgress(values, answers) {
    if (this.state.editId) {
      return Promise.reject('Edits are not saved')
    }
    const response = this.processResponse(values, answers)
    if (response) {
      return cog.post('/responses/save', { response, ...this.getSaveInfo() })
        .then(({ resumeId }) => {
          // Set resumeId as a property of this object so that the state of this observation corresponds to
          // a row in the "response_unfinished" table
          this.setResumeId(resumeId)
        }).catch(e => {
          console.error(e)
          return Promise.reject('Could not save observation')
        })
    }
    return Promise.reject('Could not save observation')
  }

  setResumeId(newResumeId) {
    // Set resumeId in state so that observationForm knows that this observation corresponds to a saved observation's
    // resumeId
    this.resumeId = newResumeId

    // Update query param so in case of a redirect to login, the observation will be resumed after redirecting back
    const newUrl = location.pathname + '?' + queryString.stringify({
      ...queryString.parse(location.search),
      resumeId: newResumeId
    })
    history.pushState({}, '', newUrl)
  }

  handleSubmit(values, answers) {
    // checkSections must come first in this method because if validation fails
    // it is expected to throw and cancel this methods execution.
    const isValid = this.checkSections(answers)
    if (!isValid) {
      return
    }

    this.setState({ submitting: true })
    const response = this.processResponse(values, answers)

    if (this.state.questionnaire.linked) {
      this.saveDataForModal = {
        observed: values.observed,
        location: values.location,
        businessUnit: values.businessUnit,
        date: values.date
      }
    }

    cog.postResponse(response, this.getSaveInfo()).then(
      () => {
        const { history } = this.props
        const { questionnaire } = this.state
        this.setState({ submittingSuccess: true, submitting: false })
        if (questionnaire && !questionnaire.linked) {
          toast.success(SUCCESS_MESSAGE)

          // Navigate back to homepage
          history.push('/')
        }
      },
      err => {
        this.setState({ submitting: false })
        if (err.status === 401 && err.resumeId) {
          this.setResumeId(err.resumeId)
          // Notifying the user of 401s happens directly in cognition.js with AuthToast, 
          // so we do not need to show an error toast for 401
          // --
        } else {
          let errorText = 'Something went wrong, please try again later.'
          if (err.status === 403) {
            errorText = err.message === 'Incorrect account domain.'
              ? 'You are logged in with an invalid account domain.  Please login with an @dteenergy account.'
              : 'Unauthorized'
          }
          const flavorAsError = err.status === 500
          if (flavorAsError) {
            toast.error(errorText)
          } else {
            toast.warning(errorText)
          }

        }
      }
    )
  }

  handleModal(key) {
    const { history } = this.props
    const { questionnaire } = this.state
    toast.success(SUCCESS_MESSAGE)
    if (key === 'redirect-to-linked' && questionnaire.linked) {
      // Save current form values in global scope
      window.lastFormValues = this.lastFormValues
      // Redirect to linked observation form
      history.push(`/observation/${questionnaire.linked.slug}`)
    } else {
      // Clear lastFormValues so they don't carry over to the next questionnaire
      this.lastFormValues = null
      // Redirect to Dashboard.
      history.push('/')
    }
  }

  modal() {
    const { submittingSuccess, questionnaire } = this.state
    if (submittingSuccess) {
      if (questionnaire && questionnaire.linked) {
        const body = (
          <span>
            Would you like to also complete a <strong>{questionnaire.linked.name}</strong> observation with this submission?
          </span>
        )
        return (
          <Modal callbackFromModal={this.handleModal}
            bodyText={body}
            footerText={{ cancel: 'No', affirm: 'Yes' }}
            callbackKey='redirect-to-linked' />
        )
      }
      return null
    }
  }

  render() {
    const { match: { params } } = this.props
    const { submitting, submittingSuccess, loading, questionnaire, template, sectionErrors,
      questionErrors, editId, authError } = this.state
    // If the questionnaire is not yet published, and the user is not an admin, redirect to unauthorized.
    // else, if the questiononaire is published or user is an admin, show the content as usual
    if (questionnaire && !questionnaire.published && this.props.user && (!this.props.user.admin && !this.props.user.superUser)) {
      return <Redirect to='/unauthorized' />
    }
    return (
      <div className='container container-observation'>
        <ErrorOverlay isVisible={!!authError} message={<span>Session expired, please <span className='fake-link' onClick={() => location.reload()}>refresh</span></span>} />
        <AccessDeniedCover questionnaire={questionnaire} />
        <LoadingOverlay isVisible={loading} isFixedPosition />
        <FormDateLimitContext.Provider value={{ startDate: this.state.formStartDateLimit, endDate: this.state.formEndDateLimit }}>
          <ObservationForm
            defaultValue={this.state.initialValues}
            sectionErrors={sectionErrors}
            questionErrors={questionErrors}
            onCancel={this.navToHomepage}
            onDiscard={this.discardObservation}
            onSubmit={this.handleSubmit}
            saveProgress={this.handleSaveProgress}
            editId={editId}
            triggerRecheckSections={this.checkSections}
            questionnaire={questionnaire}
            slug={params.slug}
            submitting={submitting}
            submittingSuccess={submittingSuccess}
            template={template}
          />
        </FormDateLimitContext.Provider>
        {this.modal()}
      </div>
    )
  }
}
export function formatTime(timeString) {
  try {
    const matches = timeString.match(/(\d{1,2}):(\d{2})\s*([aApP][mM])/)
    return `${parseInt(matches[1], 10)}:${matches[2]}${matches[3].toLowerCase()}`
  } catch (_e) {
    return undefined
  }

}

Observation.propTypes = {
  user: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
}

function mapStateToProps(state) {
  const { user } = state
  return { user }
}

class AccessDeniedCover extends Component {
  render() {
    const { questionnaire } = this.props
    // Only show this component if access is denied to the questionnaire
    if (!(questionnaire && questionnaire.isAccessDenied)) {
      return null
    }
    const qewWarning = [`The QEW observation  form is active for only Fully Qualified Electrical Workers and cannot be utilized without all the required qualifications. If you feel this is in error, then review this report for QEW compliance: `,
      <a key="linkPowerBI" target="_blank" rel="noopener noreferrer" href={`https://app.powerbi.com/groups/me/apps/df7aff74-dd3e-44e0-a79a-1d442003c35a/reports/9bf1d91c-151e-4c0b-b868-05eda7354180/ReportSectionbe3703421930e0063374?tenant=8e61d5fe-7749-4e76-88ee-6d8799ae8143&UPN=denise.prater@dteenergy.com`}>DTE QEW Report- Power BI. </a>,
      ` Please note, there is a 3 day lag time for updates to be displayed in this report.`];

    const errorMessage = questionnaire.slug === 'qualified-electrical-worker'
      ? qewWarning
      : 'You do not have permission to complete this form'
    // Prevents data entry of the observation if access is denied to the observation.  As of 2020-04-21, 
    // this is only being used for QEW.
    // The parent div prevents the user from clicking anywhere in the screen except the header
    return <div style={{
      position: 'fixed',
      zIndex: 3,
      // Leave room for header
      marginTop: '62px',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      cursor: 'not-allowed',
      border: '6px solid rgb(169, 68, 66)'
    }}>
      <ErrorBox floating message={errorMessage} style={{ bottom: 0, top: '120px', maxWidth: '600px' }} />
    </div>

  }


}

export default connect(mapStateToProps)(Observation)
