import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import DescriptionDate from '../../components/inputs/DescriptionDate.jsx'
import SafeHTML from '../../components/SafeHTML.jsx'

class QuestionTwoDatesSelect extends React.Component {
  constructor (props) {
    super(props)
  }

  generateFirstDate() {
    const {onChange, valueFirstDate} = this.props
    return (
      <div className='col-sm-4 question-description-input'>
        <DescriptionDate
          onChange={valueFirstDate => {
            onChange(valueFirstDate)
          }}
          value={valueFirstDate}
        />
      </div>
    )
  }

  generateSecondDate() {
    const {onChangeSecondRange, valueSecondDate} = this.props
    return (
      <div className='col-sm-4 question-description-input'>
        <DescriptionDate
          onChange={valueSecondDate => {
            onChangeSecondRange(valueSecondDate)
          }}
          value={valueSecondDate}
        />
      </div>
    )
  }

  render () {
    const { question, error } = this.props
    const formClasses = classNames({
      'form-group--radio': question.question_style === 'radio_buttons',
      'form-group': question.question_style === 'dropdown',
      'has-error': error
    })
    const rowClasses = classNames({
      'life-critical': question.is_life_critical && question.control_question_id === null,
      'has-quality-abbreviation': question.abbreviation === 29,
      'row': true
    })
    return (
      <div className={rowClasses}>
        <div className='col-md-12'>
          <SafeHTML markdown={question.text} />
        </div>
        <div className='col-md-12'>
          {this.generateFirstDate()}
          {this.generateSecondDate()}
          <div className={formClasses}>
          </div>
        </div>
      </div>
    )
  }
}

QuestionTwoDatesSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  validate: PropTypes.func,
  fields: PropTypes.object,
  error: PropTypes.string
}

export default QuestionTwoDatesSelect
