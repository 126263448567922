import React, { Fragment } from 'react'
import Select from 'react-select'
import ErrorMessage from '../ErrorMessage.jsx'
import { WithFormContext, CustomInput, Input } from '../../components/forms/SuperForm.jsx'

function SelectSublocation(props) {
  return <Fragment>
    {props.error ? <ErrorMessage message='Required due to precise locale' /> : null}
    <Select
      {...props}
      inputId='locale'
      classNamePrefix={'react-select'}
      isClearable
      options={sublocations} />
  </Fragment>
}
class SublocationSelectorsHolder extends React.Component {
  render() {
    return (
      <div className={'row'}>
        <div className={'col-md-12'}>
          <label htmlFor='locale'>Locale</label>
        </div>
        {this.props.excludePrecise ? (
          <div className={'col-md-12 form-group'}>
            <CustomInput {...this.props} name={this.props.name || 'sublocation'} component={SelectSublocation} />
          </div>

        ) : (
            <BothLocaleAndFree {...this.props}/>
          )}
      </div>
    )
  }
}
class _BothLocaleAndFree extends React.Component {
  render() {
    const { context, enforceGeneralToSpecific } = this.props
    // enforceGeneralToSpecific, when true, ensures that if sublocation_free_form has a value that
    // sublocation must also have a value.  This prevents users from submitting an observation
    // with a sublocation_free_form but not a sublocation.
    const requireSublocation = enforceGeneralToSpecific && !!context.values['sublocation_free_form']
    return (
      <Fragment>
        <div className={'col-md-6'}>
          <CustomInput {...this.props} name={this.props.name || 'sublocation'} component={SelectSublocation} isRequired={requireSublocation} />
        </div>
        <div className={'col-md-6 form-group'}>
          <Input id='sublocation_free_form'
            className='form-control'
            name='sublocation_free_form'
            type='text'
            placeholder={'Precise Locale...'}
          />
        </div>
      </Fragment>

    )
  }
}
const BothLocaleAndFree = WithFormContext(_BothLocaleAndFree)

export const sublocations = [
  {
    label: 'Company/Yard'
  },
  {
    label: 'Compressor Station'
  },
  {
    label: 'Construction Site'
  },
  {
    label: 'Customer Care Center'
  },
  {
    label: 'Customer Property'
  },
  {
    label: 'Dig Location'
  },
  {
    label: 'Driveway'
  },
  {
    label: 'Excavation'
  },
  {
    label: 'Parking Lot'
  },
  {
    label: 'Peaker Site'
  },
  {
    label: 'Pipeline'
  },
  {
    label: 'Solar site'
  },
  {
    label: 'Volunteer Location'
  },
  {
    label: 'Well Pad/Site'
  },
  {
    label: 'Wind Park'
  },
  {
    label: 'OTHER'
  },
  {
    label: 'Right of Way - Roadway'
  },
  {
    label: 'Right of Way - not along Roadway'
  },
  {
    label: 'Right of Way - Railroad'
  },
  {
    label: 'Trail - Unmanaged Roadway'
  },
  {
    label: 'Natural - Undisturbed'
  },
  {
    label: 'Waterway'
  },
  {
    label: 'Area - Cascades'
  },
  {
    label: 'Area - Coal Yard'
  },
  {
    label: 'Area - Demineralizer'
  },
  {
    label: 'Area - Fan'
  },
  {
    label: 'Area - Fuel Supply'
  },
  {
    label: 'Area - Generator'
  },
  {
    label: 'Area - Landscaping/Lawn'
  },
  {
    label: 'Area - Loading Dock'
  },
  {
    label: 'Area - Pump Area'
  },
  {
    label: 'Area - Receipt Meter Station'
  },
  {
    label: 'Area - Shop'
  },
  {
    label: 'Area - Spoil Pile'
  },
  {
    label: 'Area - Storage/Yard'
  },
  {
    label: 'Area - Substation Mat'
  },
  {
    label: 'Area - Switch Yard'
  },
  {
    label: 'Area - Truck'
  },
  {
    label: 'Area - Turbine'
  },
  {
    label: 'Area - Washing Station'
  },
  {
    label: 'Building - Absorber'
  },
  {
    label: 'Building - Boiler House'
  },
  {
    label: 'Building - Coal Mill'
  },
  {
    label: 'Building - Crusher House'
  },
  {
    label: 'Building - Dumper House'
  },
  {
    label: 'Building - Gate Station'
  },
  {
    label: 'Building - General Service Pump House'
  },
  {
    label: 'Building - Parking Structure/Garage'
  },
  {
    label: 'Building - Slurry house'
  },
  {
    label: 'Building - Trailer'
  },
  {
    label: 'Building - Transformer House'
  },
  {
    label: 'Building - Turbine House'
  },
  {
    label: 'Building - Other'
  },
  {
    label: 'Structure - Ash Basin'
  },
  {
    label: 'Structure - Hopper'
  },
  {
    label: 'Structure - Conveyor'
  },
  {
    label: 'Structure - Boiler'
  },
  {
    label: 'Structure - Turbine'
  },
  {
    label: 'Structure - Intake/Outfall'
  },
  {
    label: 'Structure - Silo'
  },
  {
    label: 'Structure - Tower/Pole'
  },
  {
    label: 'Structure - Roof'
  },
  {
    label: 'Structure - Bay'
  },
  {
    label: 'Structure - Dock/Deck'
  },
  {
    label: 'Structure - Elevated Walkway'
  },
  {
    label: 'Structure - Stairs, Indoor'
  },
  {
    label: 'Structure - Stairs, Outdoor'
  },
  {
    label: 'Structure - Bridge'
  },
  {
    label: 'Structure - Building Façade'
  },
  {
    label: 'Structure - Other'
  },
  {
    label: 'Room - Cafeteria/Break'
  },
  {
    label: 'Room - Restroom/Locker'
  },
  {
    label: 'Room - Gym'
  },
  {
    label: 'Room - Office Area'
  },
  {
    label: 'Room - Class/Conference/Copy'
  },
  {
    label: 'Room - Hallway/Aisle'
  },
  {
    label: 'Room - Skywalk/Breezeway'
  },
  {
    label: 'Room - Central Control'
  },
  {
    label: 'Room - Dispatch'
  },
  {
    label: 'Room - Simulator'
  },
  {
    label: 'Room - Lab'
  },
  {
    label: 'Room - Relay'
  },
  {
    label: 'Room - Switchgear'
  },
  {
    label: 'Room - Electrical'
  },
  {
    label: 'Room - Engine'
  },
  {
    label: 'Room - Mechanical'
  },
  {
    label: 'Room - Utility'
  },
  {
    label: 'Room - Stock/Tool Cage'
  },
  {
    label: 'Room - Feeder'
  },
  {
    label: 'Room - Other'
  },
  {
    label: 'Warehouse - Garage Area'
  },
  {
    label: 'Warehouse - Other'
  },
  {
    label: 'Basement/Crawlspace'
  },
  {
    label: 'Chimney'
  },
  {
    label: 'Duct'
  },
  {
    label: 'Elevator'
  },
  {
    label: 'Escalators'
  },
  {
    label: 'Sewer'
  },
  {
    label: 'Vault'
  }
  // Note, the value is just used for the selector and is irrelevant to the database
  // because the sublocation is stored as a string in the database
].map((sublocation, index) => ({ value: index, label: sublocation.label }))
export default SublocationSelectorsHolder
