import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import _get from 'lodash.get'

import { cog } from '../../api/cognition'
import { toast } from 'react-toastify'

import WeeklyCompletions from './WeeklyCompletions.jsx'
import {
  gather,
  REQUEST_UPDATE_REMINDER_EMAIL,
  RECEIVE_UPDATE_REMINDER_EMAIL,
} from '../../actions/gather'

import '../../css/dashboard.css'
import lifeCritWheelImg from '../../../assets/images/life-critical-v2-208x208.png'
import { ProgressBar } from '../../components/DataVisualization.jsx'
import LoadingOverlay from '../../components/LoadingOverlay.jsx'
import ErrorMessage from '../../components/ErrorMessage.jsx'
import {ISO_DATE_FORMAT} from '../../constants.js'



class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.getEmployeeDisplay = this.getEmployeeDisplay.bind(this)
    this.setObservationReminder = this.setObservationReminder.bind(this)
    this.getUnfinishedResponses = this.getUnfinishedResponses.bind(this)
    this.state = {
      onlySelf: true,
      ytdComplianceReport: {},
      ytdQualityReport: {},
      ytdLifeCriticalReport: {},
      responses: {},
      responsesUnfinished: {},
    }
  }

  componentDidMount() {
    this.getResponses()
    this.getUnfinishedResponses()
    this.updateYTD()
    document.addEventListener('visibilitychange', this.getUnfinishedResponses);
  }
  componentWillUnmount(){
    document.removeEventListener('visibilitychange', this.getUnfinishedResponses);
  }
  getUnfinishedResponses(){
    this.putPromiseInState(cog.get('/responses/unfinished'), 'responsesUnfinished')
  }
  updateYTD() {
    this.getYtdCompliance()
    this.getYtdQuality()
    this.getYtdLifeCritical()
  }

  getResponses() {
    const { user } = this.props
    this.putPromiseInState(cog.getResponses({
      observers: user.profile.azure_id,
      limit: 10
    }), 'responses')
  }

  putPromiseInState(promise, key) {
    this.setState({ [key]: { loading: true, error: null, data: null } })
    promise.then(data => {
      this.setState({ [key]: { loading: false, error: null, data } })
    }).catch(_ => {
      this.setState({ [key]: { loading: false, error: 'Could not load data', data: null } })
    })
  }

  getYtdCompliance() {
    const { user } = this.props
    const { onlySelf } = this.state
    const currentYear = new Date().getFullYear()
    let date = moment().year(currentYear).month(0).date(1).day(8);
    if (date.date() > 7) {
      date.day(-6);
    }
    const startDate = moment().year() === 2017 ? moment('2017-11-13').startOf('isoWeek').format(ISO_DATE_FORMAT) : date.format(ISO_DATE_FORMAT) // moment(startDate).add('1', 'weeks').format(ISO_DATE_FORMAT)
    const endDate = moment().format(ISO_DATE_FORMAT)
    this.putPromiseInState(cog.getCompliance({
      azureId: user.profile.azure_id,
      startDate,
      endDate,
      onlySelf
    }), 'ytdComplianceReport')
  }

  getYtdQuality() {
    const { user } = this.props
    const { onlySelf } = this.state
    const currentYear = new Date().getFullYear()
    let date = moment().year(currentYear).month(0).date(1).day(8);
    if (date.date() > 7) {
      date.day(-6);
    }
    const startDate = moment().year() === 2017 ? moment('2017-11-13').startOf('isoWeek').format(ISO_DATE_FORMAT) : date.format(ISO_DATE_FORMAT)
    const endDate = moment().format(ISO_DATE_FORMAT)

    this.putPromiseInState(cog.getQuality({
      azureId: user.profile.azure_id,
      startDate,
      endDate,
      onlySelf: onlySelf ? 1 : 0
    }), 'ytdQualityReport')
  }

  getYtdLifeCritical() {
    const { user } = this.props
    const { onlySelf } = this.state
    const startDate = moment().year() === 2017 ? moment('2017-11-13').startOf('isoWeek').format(ISO_DATE_FORMAT) : moment().startOf('year').format(ISO_DATE_FORMAT)
    const endDate = moment().format(ISO_DATE_FORMAT)
    this.putPromiseInState(cog.getLifeCritical({
      azureId: user.profile.azure_id,
      startDate,
      endDate,
      onlySelf
    }), 'ytdLifeCriticalReport')

  }

  getEmployeeDisplay(response) {
    const { is_free_form: freeForm, free_form_observed_name: name, employeesObserved: observed } = response
    try {
      if (freeForm === true && name !== '') {
        return name
      }
      if (observed.length === 1) {
        return observed[0].user.display_name
      } else if (observed.length > 1) {
        return observed[0].user.display_name + ', ...'
      }
      return 'Anonymous'
    } catch (e) {
      return '-'
    }
  }
  renderDeleteButton(response) {
    const twoWeeksAgo = moment().subtract(2, 'weeks')
    // Only responses made within the last two weeks may be deleted
    if (moment(response.created_at).isAfter(twoWeeksAgo)) {
      return <button className={'btn btn-danger btn-xs'} style={{ marginLeft: '5px' }} onClick={() => {
        if (window.confirm(`Are you sure you want to delete this response?`)) {
          cog.deleteResponseById(response.id).then(() => {
            this.getResponses()
          }).catch(() => {
            toast.error('There was a problem deleting this response.')
          })
        }
      }}>Delete</button>
    } else {
      return null
    }
  }
  unfinished() {
    const responsesUnfinished = this.state.responsesUnfinished || {}
    if (responsesUnfinished.loading) {
      return null
    }
    let rows = []
    if (responsesUnfinished.data) {
      rows = responsesUnfinished.data.map(data => {
        const slug = _get(data, 'response.questionnaire.slug')
        if(slug && data.id){
          return (
            <tr key={data.id}>
              <td>
                {this.getEmployeeDisplay(data.response)}
              </td>
              <td className='align-middle'>{moment.duration(moment().diff(moment(data.saved_at))).humanize()} ago</td>
              <td className='align-middle'>{_get(data, 'response.questionnaire.name')}</td>
              <td className='align-right'>
                <Link target='_blank' to={`/observation/${slug}?resumeId=${data.id}`} className='btn btn-warning btn-xs'>
                  Resume
                </Link>
                <button className={'btn btn-danger btn-xs'} style={{ marginLeft: '5px' }} onClick={() => {
                  if (window.confirm(`Are you sure you want to discard this observation?`)) {
                    cog.delete('/responses/unfinished', { id: data.id })
                      .then(() => {
                        this.getUnfinishedResponses()
                      }).catch(() => {
                        toast.error('There was a problem discarding this observation.')
                      })
                  }
                }}>Discard</button>
              </td>
            </tr>
          )
        }
      })
    }
    if (rows.length === 0) {
      return null
    }
    return <div className='panel panel-default'>
        <div className='panel-heading'>In Progress Observations</div>
        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th>Observed</th>
                <th className='align-middle'>Saved</th>
                <th className='align-middle'>Type</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
      </div>
  }

  recent() {
    const responses = this.state.responses || {}
    if (responses.loading) {
      return (
        <tr key='noRecentObservations'>
          <td className='text-center' colSpan={4}>
            <i className='fa fa-circle-notch fa-spin fa-3x fa-fw' />
            <span className='sr-only'>Loading...</span>
          </td>
        </tr>
      )
    }
    let responseRows = window.recentlyMadeAnonObservation
      // Show an optional helpful tip when users have just submitted an anonymous observation to explain why it wont show
      // up in "My Recent Activity".  This uses global scope because it need not be managed by React
      ? [<tr key={'anon-info-alert'} className='alert-primary' style={{ backgroundColor: '#cce5ff' }}>
        <td colSpan={100} style={{ textAlign: 'center' }}>Observations submitted anonymously will not show up in Recent Activity</td>
      </tr>]
      : []
    if (responses.data) {
      responseRows = responseRows.concat(responses.data.results.map(response => {
        return (
          <tr key={response.id}>
            <td>
              {this.getEmployeeDisplay(response)}
            </td>
            <td className='align-middle'>{moment(response.observed_at).utc().format('LL')}</td>
            <td className='align-middle'>{response.questionnaire && response.questionnaire.name}</td>
            <td className='align-right'>
              <Link target='_blank' to={`/response/${response.id}`} className='btn btn-primary btn-xs'>
                View
              </Link>
              {this.renderDeleteButton(response)}
            </td>
          </tr>
        )
      }))
    }
    if (responseRows.length === 0) {
      responseRows = responseRows.concat(
        <tr key='noRecentObservations'>
          <td className='text-center' colSpan={4}>
            <em>No Recent Observations</em>
          </td>
        </tr>
      )
    }
    return responseRows
  }

  ytdReports() {
    const { ytdComplianceReport, ytdQualityReport, ytdLifeCriticalReport } = this.state

    const combineReports = [
      {
        key: 'Compliance',
        report: ytdComplianceReport
      },
      {
        key: 'Quality',
        report: ytdQualityReport
      },
      {
        key: 'Life Critical',
        report: ytdLifeCriticalReport,
        subtext: '*% of Field SWOs only'
      }
    ]

    return (
      // margin:0 keeps the spinner loader overlays centered and keeps them from bleeding over the edge
      <div style={{ margin: 0 }}>
        {combineReports.map(({ key, report, subtext }, i) => {
          const loading = report && report.loading || false
          const error = report && report.error || null
          const data = report && report.data || {}
          const startDate = moment(data.startDate).format('MMM D, YYYY')
          const endDate = moment(data.endDate).format('MMM D, YYYY')
          const displayValue = Math.round(data.value * 10000) / 100
          return (
            <div className={`col-md-4 ${i === 1 ? 'middle-graph' : ''}`} key={key}>
              <LoadingOverlay isVisible={loading} />
              <div className=''>
                <strong>{key}</strong> {startDate && endDate && (`for ${startDate === endDate ? startDate : `${startDate} - ${endDate}`}`)} <h3>{!isNaN(displayValue) && `${displayValue}%`}&nbsp;</h3>
                <ProgressBar percentage={displayValue} />
                <ErrorMessage message={error} />
                {/* The &nbsp; takes up space even if subtext isn't present so that all report divs render the same height */}
                <div className='text-right'>&nbsp;{subtext}</div>
              </div>
            </div>
          )
        }
        )}
      </div>
    )
  }

  setObservationReminder() {
    const { dispatch, user } = this.props
    const actions = {
      request: REQUEST_UPDATE_REMINDER_EMAIL,
      receive: RECEIVE_UPDATE_REMINDER_EMAIL
    }
    // reminder_email comes from the user profile and
    // shouldn't be refactored out of redux until the profile is
    dispatch(gather(actions, cog.updateReminderEmail({
      id: user.profile.id,
      reminder_email: !user.profile.reminder_email
    })))
  }

  render() {
    const { user } = this.props
    return (
      <div className='container container-dashboard'>
        <div className='row greeting'>
          <div className='col-md-12 hidden-xs greeting-actions'>
            <Link to='/observation/office' className='btn btn-primary'>Office Observation</Link>
            <Link to='/observation/field' className='btn btn-primary'>Field Observation</Link>
            <Link to='/observation/WFH' className='btn btn-primary'>Virtual Check-In</Link>
            <Link to='/help' className='pull-right'><button className="btn btn-primary btn-help">Help</button></Link>
          </div>
          <div className='col-md-12 hidden-sm hidden-md hidden-lg'>
            <Link to='/observation/office' className='btn btn-block btn-primary'>Office Observation</Link>
            <Link to='/observation/field' className='btn btn-block btn-primary'>Field Observation</Link>
            <Link to='/observation/WFH' className='btn btn-block btn-primary'>Virtual Check-In</Link>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <WeeklyCompletions />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <div className='panel panel-default'>
              <div className='panel-heading'>
                {this.state.onlySelf ? 'Your Personal Year-to-Date Metrics' : 'You and Your Team\'s Year-to-Date Metrics'}
              </div>
              <div className='panel-body' >
                {user && user.is_manager && (
                  <div className='col-md-12'>
                    <div style={{
                      padding: '5px 10px 5px 0',
                      display: 'inline-block',
                      verticalAlign: 'top'
                    }}>Include direct and indirect reports</div>
                    <label className='toggle_switch'>
                      <input
                        type='checkbox'
                        onChange={() => {
                          this.setState({
                            // toggle to inverse
                            onlySelf: !this.state.onlySelf
                          }, this.updateYTD)
                        }}
                        checked={!this.state.onlySelf}
                      />
                      <span className='toggle_switch-slider toggle_switch--round' />
                    </label>
                  </div>
                )}
                {this.ytdReports()}
              </div>
            </div>

          </div>
        </div>
        <div className='row'>
          <div className='col-md-8'>
            {this.unfinished()}
            <div className='panel panel-default'>
              <div className='panel-heading'>My Recent Activity</div>
              <div className='table-responsive'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>Observed</th>
                      <th className='align-middle'>Observed On</th>
                      <th className='align-middle'>Type</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {this.recent()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='panel panel-default'>
              <div className='panel-heading'>
                <i className='fa fa-bell fa-lg' aria-hidden='true' />
                <span> Forget an Observation? </span>
              </div>
              <div className='reminder-text-padding'>
                <p>Receive an email reminder every Thursday at 1:00pm when you have not completed an observation for the week.</p>
                <p>You may turn your email reminder on or off at any time.</p>
                <label className='toggle_switch' style={{ marginTop: '15px' }}>
                  <input
                    type='checkbox'
                    onChange={() => this.setObservationReminder()}
                    checked={this.props.user.profile.reminder_email}
                  />
                  <span className='toggle_switch-slider toggle_switch--round' />
                </label>
              </div>
            </div>
            <div className='panel panel-default'>
              <div className='panel-heading'>
                <i className='fa fa-table fa-lg' aria-hidden='true' />
                <span> Reports </span>
              </div>
              <div className='panel-body'>
                <div><Link to='/reports/ComplianceWeekly'>Leader Compliance</Link></div>
                <div><Link to='/reports/improvement-opportunities'>Improvement Opportunities</Link></div>
                <div><Link to='/search'>Observation Search</Link></div>
              </div>
            </div>
            <div className='panel panel-default'>
              <div className='panel-heading'>
                <i className='fa fa-chart-bar fa-lg' aria-hidden='true' />
                <span> Dashboards </span>
              </div>
              <div className='panel-body'>
                <div><Link to='/reports/corporate-metrics'>Corporate Metrics</Link></div>
                <div><Link to='/reports/leader-comparison'>Leader Comparison</Link></div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-mid-8' />
          <div className='col-md-4 col-xs-12 thumbnail life-critical-wheel' >
            <img src={lifeCritWheelImg} alt='Responsive image' />
          </div>
        </div>
      </div>
    )
  }
}

Dashboard.propTypes = {
  dispatch: PropTypes.func,
  user: PropTypes.object,
}

function mapStateToProps(state) {
  const { user } = state
  return {
    user,
  }
}

export default connect(mapStateToProps)(Dashboard)
