import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'
import PropTypes from 'prop-types'

import { cog } from '../api/cognition'
import Pagination from '../components/Pagination.jsx'
import ResponseSearchForm from '../components/forms/ResponseSearchForm.jsx'
import {ISO_DATE_FORMAT} from '../constants.js'


const NUM_ITEMS_PER_PAGE = 10

class ObservationHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading:false,
      error:null,
      responses:null,
      pageNum: 1,
      searchParams: {}
    }

    this.getEmployeeDisplay = this.getEmployeeDisplay.bind(this)
    this.goToPage = this.goToPage.bind(this)
    this.searchResponses = this.searchResponses.bind(this)
  }

  searchResponses(pageNum = 1) {
    const {searchParams} = this.state
    if (!searchParams) {
      console.error('Cannot search without any searchParams')
      return
    }
    const { id, range, rolldown, observers=[], observed=[], businessUnits=[], locations=[] } = searchParams
    const params = {
      id: !isNaN(parseInt(id)) ? id : undefined,
      observers: !rolldown ? observers.map(user => user.value).join(',') : undefined,
      rolldownObservers: rolldown ? observers.map(user => user.value).join(',') : undefined,
      observed: observed.map(user => user.value).join(','),
      organizations: businessUnits.map(x => x.value).join(','),
      locations: locations.map(loc => loc.value).join(','),
      startDate: moment(range.startDate).format(ISO_DATE_FORMAT),
      endDate: moment(range.endDate).format(ISO_DATE_FORMAT),
      pageNum,
      numItemsPerPage: NUM_ITEMS_PER_PAGE
    }
    this.setState({pageNum, loading:true})
    cog.getResponses(params).then(responses => {
      this.setState({responses})
    }).catch(_ => {
      toast.error('Something went wrong, please try again later.')
    }).then(() => {
      this.setState({loading:false})
    })
  }

  getEmployeeDisplay(response) {
    const { employeesObserved: observed, free_form_observed_name: name, company } = response
    if (observed.length > 1) {
      return `${observed[0].user.display_name}, ...`
    } else if (observed.length > 0) {
      return observed[0].user.display_name
    }
    if (response.is_free_form === true) {
      if (name !== '' || company) {
        if (company) {
          return company.name
        }
        return name
      }
    }
    return 'Anonymous'
  }

  getCreatedByDisplay(createdBy) {
    if (createdBy !== null) {
      return createdBy.display_name
    }
    return 'Anonymous'
  }

  goToPage(page) {
    this.searchResponses(page)
  }

  responseRows() {
    const { responses, loading } = this.state
    if (loading) {
      return (
        <tr>
          <td className='text-center' colSpan={6}>
            <i className='fa fa-circle-notch fa-spin fa-3x fa-fw' />
            <span className='sr-only'>Loading...</span>
          </td>
        </tr>
      )
    }
    if (responses && responses.results) {
      const rows = responses.results.map(response => {
        return (
          <tr key={response.id}>
            <td className='align-middle'>
              <Link target='_blank' to={`/response/${response.id}`}>
                {response.id}
              </Link>
            </td>
            <td className='align-middle'>
              {this.getCreatedByDisplay(response.createdBy)}
            </td>
            <td scope='row' className='align-middle'>
              {this.getEmployeeDisplay(response)}
            </td>
            <td>
              {response.questionnaire.name}
            </td>
            <td className='align-middle'>
              {moment(response.observed_at).utc().format('LL')}
            </td>
          </tr>
        )
      })
      if (rows.length === 0) {
        return (
          <tr>
            <td className='text-center' colSpan={6}>
              <em>No History For Selected Filters</em>
            </td>
          </tr>
        )
      }
      return rows
    }
  }

  render() {
    const {responses, pageNum, loading} = this.state
    return (
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='page-header'>
              <h3 className='text-center'>Observation Search</h3>
            </div>
          </div>
          <div className='col-md-12'>
            <ResponseSearchForm
              handleSubmit={searchParams => {
                this.setState({searchParams}, () => {
                  // Every new search returns to the first page
                  this.searchResponses(1)
                })
              }}
              handleChange={() => {
                // When search params change, clear responses (which are now stale)
                // to prevent any confusion.  Responses should always correspond to
                // the displayed search params
                this.setState({responses:null, pageNum:1})
              }}
              loading={loading} />
          </div>
          {!!responses && (
            <div className='col-md-12'>
              <div className='panel panel-default'>
                <div className='panel-heading'>Observations</div>
                <div className='table-responsive'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Observed By</th>
                        <th>Observed</th>
                        <th>Questionnaire</th>
                        <th>Observed On</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.responseRows()}
                    </tbody>
                  </table>
                </div>
              </div>
              {responses && (responses.total > NUM_ITEMS_PER_PAGE)
                ? <div className='row'>
                  <div className='col-xs-12'>
                    <div className='pull-right'>
                      <Pagination
                        numberOfRows={responses.total}
                        rowsPerPage={NUM_ITEMS_PER_PAGE}
                        currentPage={pageNum}
                        selectPage={this.goToPage}
                      />
                    </div>
                  </div>
                </div>
                : null}
            </div>

          )}
        </div>
      </div>
    )
  }
}

ObservationHistory.propTypes = {
  dispatch: PropTypes.func,
}

export default ObservationHistory
